import React from 'react';

import { componentPropTypes } from '../../shared';

const Message = props => (
  <div className="message-component" key={props.id}>
    {props.fields.map(field => renderField(field))}
  </div>
);

const createMarkup = html => ({ __html: html });

const renderField = field => <div key={field.id} dangerouslySetInnerHTML={createMarkup(field?.rendered_placeholder)} />;

Message.propTypes = {
  ...componentPropTypes
};

export default Message;
