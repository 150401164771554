import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, ListItem, Searchbar } from 'framework7-react';

import { F7 } from 'Services';
import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { CheckIn } from 'Repositories';

import './style.scss';
import { getFieldProp } from '../../shared';
import { AddressFields } from './components';
import UnavailableComponent from '../UnavailableComponent';

class Address extends Component {
  constructor() {
    super();

    this.state = {
      autocomplete: null
    };
  }

  componentDidUpdate() {
    if (!this.state.autocomplete) {
      this.setState({
        autocomplete: this.createF7Autocomplete()
      });
    }
  }

  createF7Autocomplete = () => {
    const { currentCheckInAdress } = this.props;
    return F7.autocomplete.create({
      inputEl: '#autocomplete-dropdown',
      openIn: 'dropdown',
      valueProperty: 'full_address',
      textProperty: 'full_address',
      limit: 10,
      source: function (query, render) {
        const results = [];
        if (query.length === 0) {
          render(results);
          return;
        }

        if (currentCheckInAdress) {
          for (let i = 0; i < currentCheckInAdress.length; i++) {
            if (currentCheckInAdress[i].full_address.toLowerCase().indexOf(query.toLowerCase()) >= 0)
              results.push(currentCheckInAdress[i]);
          }
        }

        render(results);
      },
      on: {
        change: value => {
          this.setValues(null, null, value[0], true);
        }
      }
    });
  };

  setValues = (oldProps, individualKeyValue, newProps, searchProps = false) => {
    const initialState = {
      search_address: '',

      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      City: '',
      PostCode: ''
    };

    let newState;

    if (searchProps) {
      const newPropsMapped = {
        AddressLine1: newProps.address_line1 || '',
        AddressLine2: newProps.address_line2 || '',
        AddressLine3: newProps.address_line3 || '',
        City: newProps.town || '',
        PostCode: newProps.postcode || '',
        search_address: newProps.full_address
      };

      newState = Object.assign(initialState, newPropsMapped);
    } else {
      newState = Object.assign(initialState, oldProps, individualKeyValue, {
        search_address: ''
      });
    }

    this.props.updateValue({ value: newState });
  };

  render = () => {
    const { country, name, fields, getUKAddress, isOffline, updateTransientProp, transient } = this.props;
    const { disabled = true } = transient;

    const AddressCountries = ['BE', 'DE', 'FR', 'IE', 'UK'];

    const handleUKSearch = (getUKAddress, updateTransientProp, e) => {
      const text = e.target.value.trim();
      if (text?.length === 0) {
        updateTransientProp('uk_address', []);
      } else {
        getUKAddress({ text });
      }
    };

    const onClickUKHandler = ({ updateTransientProp, item }) => {
      CheckIn.getUKAddress(item).then(cb => {
        if (cb.status === 200) {
          const address_uk_find = JSON.parse(cb.data);
          this.setValues(null, { full_address: address_uk_find[0].full_address }, address_uk_find[0], true);
          updateTransientProp('uk_address', []);
          updateTransientProp('disabled', true);
        }
      });
    };

    return AddressCountries.includes(country) ? (
      <div>
        <RowView paddingLeft={16} marginTop={16}>
          <div className="item-title item-label">{name}</div>
        </RowView>

        {country === 'UK' && !isOffline && (
          <>
            <RowView paddingHorizontal={8}>
              <Searchbar
                init={false}
                placeholder={getFieldProp(fields, 'search_address').placeholder}
                onChange={handleUKSearch.bind(null, getUKAddress, updateTransientProp)}
                onClickClear={updateTransientProp.bind(null, 'uk_address', [])}
              />
            </RowView>
            <div className="px-16">{!disabled && I18n.t('flow:components.address.loqateDown')}</div>
          </>
        )}

        {transient.uk_address && !!transient.uk_address.length && (
          <List className="mt-0">
            {transient.uk_address.map((item, index) => {
              const { id, type, full_address } = item;
              const address_params = {
                id,
                type,
                text: full_address.split('(More Addresses)')[0].trim()
              };

              return (
                <ListItem
                  key={index}
                  onClick={
                    item.type === 'Address'
                      ? onClickUKHandler.bind(null, { updateTransientProp, item })
                      : getUKAddress.bind(null, address_params)
                  }>
                  {item.full_address}
                </ListItem>
              );
            })}
          </List>
        )}

        {(country === 'FR' || country === 'BE') && (
          <RowView paddingHorizontal={16} alignItems={'stretch'}>
            <div className="no-hairlines-md flex4">
              <div className="item-inner">
                <div className="item-input-wrap">
                  <input
                    id="autocomplete-dropdown"
                    type="text"
                    placeholder={getFieldProp(fields, 'search_address').placeholder}
                  />
                </div>
              </div>
            </div>
          </RowView>
        )}

        <AddressFields {...this.props} setValues={this.setValues} />
      </div>
    ) : (
      <UnavailableComponent {...this.props} />
    );
  };
}

Address.propTypes = {
  component_key: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  fields: PropTypes.array,
  getUKAddress: PropTypes.func,
  isOffline: PropTypes.bool,
  name: PropTypes.string,
  shouldShowError: PropTypes.bool,
  transient: PropTypes.object,
  updateValue: PropTypes.func,
  updateTransientProp: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object)
};

export default Address;
