import React from 'react';
import PropTypes from 'prop-types';

import { generateComponentProperties, getAge, isValidIBAN, mockFunction } from 'Helpers';
import {
  Address,
  BankAccount,
  CheckBoxRadio,
  ConfirmEmail,
  FlowSignature,
  Message,
  Text,
  UnavailableComponent
} from 'FlowComponentTypes';

import { bankComponentOnlineFields } from './shared';

const ComponentTypes = {
  address: Address,
  bank_account: BankAccount,
  bank_registration_lookup: Text,
  checkbox: CheckBoxRadio,
  confirmed_email: ConfirmEmail,
  date: Text,
  message: Message,
  radio: CheckBoxRadio,
  select: Text,
  signature: FlowSignature,
  text: Text
};

const customOperands = ['email', 'iban'];

const defaultIgnoredRequiredFields = [
  'Letter',
  'search_address',
  'ExtraInfo',
  'ExtraInfo2',
  'DependentLocality',
  'DoubleDependentLocality',
  'validationStatus',
  'validationMessage',
  'bankName',
  'bic'
];

const ignoreCountryBasedRequiredFields = {
  BE: [...defaultIgnoredRequiredFields, 'Number'],
  DE: [...defaultIgnoredRequiredFields, 'Number'],
  FR: [...defaultIgnoredRequiredFields, 'Number'],
  IE: [...defaultIgnoredRequiredFields, 'Number'],
  UK: [...defaultIgnoredRequiredFields]
};

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
);

const validationFunctions = {
  dynamicValidation: (operator = null, operand = null, value = null, inputValue = null) => {
    switch (operator) {
      case 'less_than_or_equal_to':
        switch (operand) {
          case 'max_length':
            return inputValue.length <= value;
          case 'max_range':
            return parseFloat(inputValue) <= parseFloat(value);
          case 'max_age':
            return getAge(inputValue) <= parseFloat(value);
        }
        break;

      case 'greater_than_or_equal_to':
        switch (operand) {
          case 'min_length':
            return inputValue.length >= value;
          case 'min_range':
            return parseFloat(inputValue) >= parseFloat(value);
          case 'min_age':
            return getAge(inputValue) >= parseFloat(value);
        }
        break;

      case null:
      case '':
        switch (operand) {
          case 'email':
            return inputValue === null || inputValue.length === 0 || emailRegex.test(inputValue.toLowerCase());
        }
        switch (operand) {
          case 'iban':
            return isValidIBAN(inputValue);
        }
    }
    return false;
  },
  required: value => `${value}`.length > 0
};

const generateValidations = ({ country, fields, required: isRequired }) => {
  const validations = [];
  const { dynamicValidation, required } = validationFunctions;
  const ignoreRequiredFields = ignoreCountryBasedRequiredFields[country] || defaultIgnoredRequiredFields;

  if (fields.length > 0) {
    const hasMultipleFields = fields.length > 1;
    for (const field of fields) {
      if (isRequired && !ignoreRequiredFields.includes(field.name)) {
        validations.push({
          key: hasMultipleFields ? field.name : null,
          f: required
        });
      }
      const fieldValidations = field.validations;
      if (fieldValidations.length > 0) {
        for (const { value, operator, operand } of fieldValidations) {
          if (
            customOperands.includes(operand) ||
            (value !== null && value !== undefined && typeof value === 'string' && value.length !== 0)
          ) {
            validations.push({
              key: hasMultipleFields ? field.name : null,
              f: dynamicValidation.bind(null, operator, operand, value)
            });
          }
        }
      }
    }
  }

  return validations;
};

class Component extends React.Component {
  constructor(props) {
    super();
    const { component, country, referenced_products } = props;

    if (component.component_type === 'product') {
      component.referenced_products = referenced_products;
    }

    const componentProperties = generateComponentProperties(component.properties);

    const validations = generateValidations({
      country,
      fields: component.fields,
      ...componentProperties
    });

    this.state = {
      Comp: ComponentTypes[component.type] || UnavailableComponent,
      component,
      ...componentProperties,
      validations,
      hasValidations: validations.length > 0,
      hasLogic: component.logic_rules.length > 0
    };
  }

  render = () => {
    const {
      componentFlowAgreementValue,
      componentFlowValue,
      currentCheckInAdress,
      currentPageIndex,
      forceUpdate,
      getUKAddress,
      isDirty,
      isOffline,
      isValid,
      mode,
      pageIsInvalidOnNext,
      referenced_products,
      transient,
      updateTransientProp,
      userFullName,
      visible
    } = this.props;
    const { Comp, component, hasLogic, required, ...rest } = this.state;

    return (hasLogic && visible) || !hasLogic ? (
      <Comp
        agreementValue={componentFlowAgreementValue}
        forceUpdate={forceUpdate}
        key={component.id}
        referenced_products={referenced_products}
        updateValue={this.runValidationsAndDispatchValueUpdate}
        currentCheckInAdress={currentCheckInAdress}
        currentPageIndex={currentPageIndex}
        mode={mode}
        getUKAddress={getUKAddress}
        isOffline={isOffline}
        transient={transient}
        {...component}
        updateTransientProp={updateTransientProp}
        userFullName={userFullName}
        value={componentFlowValue}
        required={required}
        shouldShowError={(pageIsInvalidOnNext && required && !isDirty) || (!isValid && isDirty === true)}
        {...rest}
      />
    ) : null;
  };

  runValidationsAndDispatchValueUpdate = updateValues => {
    const { value, updatingKey } = updateValues;
    const { component, hasValidations, validations, showInSummaryPage } = this.state;
    const {
      invalidateComponent,
      isValid: previousIsValid,
      mode,
      pageId,
      updateCurrentFlowData,
      validateComponent,
      validateOnlineBankAccount,
      visible
    } = this.props;
    const onlineValidatedComponentsByType = {
      bank_account: bankComponentOnlineFields.includes(updatingKey) ? validateOnlineBankAccount : mockFunction
    };
    const hasOnlineValidation = Object.prototype.hasOwnProperty.call(onlineValidatedComponentsByType, component.type);
    updateValues.visible = visible;
    updateValues.isValid = previousIsValid === undefined ? true : previousIsValid;
    updateValues.showInSummaryPage = showInSummaryPage;
    let isValid = true;

    if (hasValidations || hasOnlineValidation) {
      for (let i = 0; i < validations.length && isValid; i++) {
        const validation = validations[i];
        const validateValue = validation.key
          ? value[validation.key]
          : updateValues.isProduct
          ? updateValues.productValue
          : value;

        // TODO remove this lates. Might inject regresion bugs.
        // if (validateValue.length > 0 || typeof validateValue === 'object') {
        isValid = validation.f(validateValue);
        // }

        if (mode === 'agent' && component.component_type === 'signature') {
          isValid = updateValues.agreement === true;
        }
      }
      if (component.component_type === 'address_uk' || component.component_type === 'address') {
        const isCityNotEmpty = value.City?.trim() !== '';
        const isLine1NotEmpty = value.AddressLine1?.trim() !== '';
        const isPostCodeNotEmpty = value.PostCode?.trim() !== '';

        const isAddressComplete = isCityNotEmpty && isPostCodeNotEmpty;

        isValid = isAddressComplete && isLine1NotEmpty;
      }

      if (isValid !== previousIsValid || hasOnlineValidation) {
        updateValues.isValid = isValid;
        if (isValid) {
          if (hasOnlineValidation) {
            onlineValidatedComponentsByType[component.type](component, pageId, updateValues);
          } else {
            validateComponent(pageId, component.component_key);
          }
        } else {
          invalidateComponent(pageId, component.component_key);
        }
      }
    }

    updateCurrentFlowData(component.component_key, updateValues);
  };
}

Component.propTypes = {
  component: PropTypes.object,
  componentFlowAgreementValue: PropTypes.bool,
  componentFlowData: PropTypes.object,
  componentFlowValue: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  currentPageIndex: PropTypes.number,
  forceUpdate: PropTypes.number,
  getUKAddress: PropTypes.func,
  invalidateComponent: PropTypes.func,
  isDirty: PropTypes.any,
  isOffline: PropTypes.bool,
  isValid: PropTypes.any,
  mode: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageIsInvalidOnNext: PropTypes.bool,
  referenced_products: PropTypes.array,
  transient: PropTypes.object,
  updateCurrentFlowData: PropTypes.func,
  userFullName: PropTypes.string,
  updateTransientProp: PropTypes.func,
  validateComponent: PropTypes.func,
  validateOnlineBankAccount: PropTypes.func,
  visible: PropTypes.bool
};

export default Component;
