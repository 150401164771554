import React from 'react';
import { ListInput } from 'framework7-react';
import Cleave from 'cleave.js';

import { I18n } from 'Locales';
import { idFilter } from 'Helpers';

import { componentPropTypes } from '../../shared';

const blurActiveElement = () => {
  document.activeElement.blur();
};

const getType = component_type => {
  switch (component_type) {
    case 'birth_date':
    case 'date':
      return 'datepicker';

    case 'email':
      return 'email';

    case 'phone':
      return 'number';

    case 'product':
    case 'select':
      return 'select';

    case 'area':
      return 'textarea';

    default:
      return 'text';
  }
};

class Text extends React.Component {
  constructor() {
    super();
    this.state = {
      cleave: null
    };
  }
  componentDidUpdate = () => {
    if (this.props.component_type === 'bank_registration_lookup' && !this.state.cleave) {
      this.setState({ cleave: this.createCleave() });
    }
  };

  createCleave = () => {
    const { component_key } = this.props;
    const cleaveIdentifier = `.cleave-iban-input-${component_key} input:first-of-type`;
    return new Cleave(cleaveIdentifier, {
      delimiter: ' ',
      delimiterLazyShow: true,
      blocks: [4, 4, 4, 4, 4, 4, 4, 4, 2], // up to 34 chars
      uppercase: true,
      onValueChanged: this.update
    });
  };

  render = () => {
    const { component_key, component_type, fields, name, shouldShowError, value } = this.props;
    const type = getType(component_type);
    const isIBAN = component_type === 'bank_registration_lookup';
    const isDatepicker = type === 'datepicker';
    let componentValue = value;

    if (isIBAN) {
      componentValue = value[fields[0].name] || '';
    }
    if (isDatepicker) {
      componentValue = value ? [new Date(value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'))] : [];
    }

    return (
      <ListInput
        className={`cleave-iban-input-${component_key}`}
        label={name}
        type={type}
        {...(type === 'datepicker' && {
          calendarParams: {
            dateFormat: 'dd/mm/yyyy',
            closeOnSelect: true,
            on: {
              closed: this.updateDate,
              open: blurActiveElement
            }
          }
        })}
        placeholder={fields[0]?.placeholder}
        value={componentValue}
        onChange={this.update}
        errorMessageForce={shouldShowError}
        errorMessage={I18n.t('flow:generalError')}>
        {type === 'select' && <option key={'placeholderSelect'}>{fields[0]?.placeholder}</option>}

        {type === 'select' && this.renderSelectOptions()}
      </ListInput>
    );
  };

  renderSelectOptions = () => {
    const { component_type, fields, referenced_products } = this.props;
    if (component_type === 'product') {
      return referenced_products.map(rp => (
        <option key={rp.id} value={rp.id}>
          {rp.name}
        </option>
      ));
    }

    return fields[0].choices.map(choice => (
      <option key={choice} value={choice}>
        {choice}
      </option>
    ));
  };

  update = e => {
    const { updateValue, id, component_type: component, fields, referenced_products } = this.props;
    const { value } = e.target;
    const isProduct = component === 'product';

    const data = {
      value,
      id,
      component
    };

    if (component === 'bank_registration_lookup') {
      const bankValue = {};
      bankValue[`${fields[0].name}`] = data.value;
      data.value = bankValue;
    }

    if (isProduct) {
      data.isProduct = true;
      data.productValue = referenced_products.filter(idFilter.bind(this, parseInt(data.value)))[0].id;
    }

    updateValue(data);
  };

  updateDate = e => {
    if (e.value[0]) {
      this.update({
        target: {
          value: new Date(e.value[0]).toLocaleDateString('en-GB')
        }
      });
    }
  };
}

Text.propTypes = {
  ...componentPropTypes
};

export default Text;
