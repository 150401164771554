import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { BlockTitle, List, ListInput, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { isSmallDevice } from 'Helpers';
import { Button, Card } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { AgentInfo, SummarySignature } from 'FlowComponentTypes';

import { getFieldProp, signatureStyle } from '../Component/shared';

const filterChoiceTitle = (choice, rf) => choice == rf.id;

const renderAddress = (id, name, value) => {
  const { AddressLine1, AddressLine2, AddressLine3, City, PostCode } = value;

  return (
    <div key={id + 'address_summary'}>
      <RowView paddingLeft={16} marginTop={16}>
        <div className="item-title item-label">{name}</div>
      </RowView>

      <ColumnView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.line1')}
            placeholder={I18n.t('flow:components.address.line1')}
            value={AddressLine1}
            className={'flex4 minWidth320'}
            disabled
          />
        </RowView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.line2')}
            placeholder={I18n.t('flow:components.address.line2')}
            value={AddressLine2}
            className={'flex4 minWidth320'}
            disabled
          />
        </RowView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.line3')}
            placeholder={I18n.t('flow:components.address.line3')}
            value={AddressLine3}
            className={'flex4 minWidth320'}
            disabled
          />
        </RowView>

        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.postalCodeLabel')}
            placeholder={I18n.t('flow:components.address.postalCodePlaceholder')}
            value={PostCode}
            className={'flex4'}
            disabled
          />
        </RowView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.cityLabel')}
            placeholder={I18n.t('flow:components.address.cityPlaceholder')}
            value={City}
            className={'flex4 minWidth320'}
            disabled
          />
        </RowView>
      </ColumnView>
    </div>
  );
};

const renderComponent = (currentFlowData, referenced_products, component) =>
  currentFlowData[component.component_key]?.showInSummaryPage
    ? renderShowComponent(component, currentFlowData[component.component_key].value, referenced_products)
    : null;

const renderPageBlocks = (showTitle, currentFlowData, referenced_products, page) =>
  showTitle[page.id] && (
    <Fragment key={page.id + 'summary'}>
      <BlockTitle medium>{page.name}</BlockTitle>

      <List noHairlines>
        <ul>{page.components.map(renderComponent.bind(this, currentFlowData, referenced_products))}</ul>
      </List>
    </Fragment>
  );

const renderShowComponent = (component, value, referenced_products) => {
  const { component_type, fields, id, name, type } = component;
  const isProduct = component_type === 'product';

  if (type === 'confirmed_email') {
    const { email, emailConfirmation } = value;

    return (
      <>
        <ListInput label={I18n.t('flow:components.confirmEmail.email')} value={email} disabled />

        <ListInput label={I18n.t('flow:components.confirmEmail.confirmEmail')} value={emailConfirmation} disabled />
      </>
    );
  }

  if (type === 'text' || type === 'select') {
    return (
      <ListInput
        key={id + 'summary'}
        label={name}
        type="text"
        value={!isProduct ? value : referenced_products.filter(filterChoiceTitle.bind(this, value))[0].name}
        disabled></ListInput>
    );
  }

  if (type === 'bank_account') {
    return renderBankAccount(id, component, value, name);
  }

  if (type === 'bank_registration_lookup') {
    return (
      <ListInput key={id + 'summary'} label={name} type="text" value={value.registrationNumber} disabled></ListInput>
    );
  }

  if (type === 'checkbox' || type === 'radio') {
    const isRadio = type === 'radio';
    const isCheckBox = type === 'checkbox';

    let choices = fields[0].choices;

    if (component_type === 'product') {
      const mapId = rf => rf.id;
      choices = referenced_products.map(mapId.bind(this));
    }

    const checkValue = (choice, value, type) => {
      if (type === 'radio') {
        return choice == value;
      }

      return value.indexOf(choice) >= 0;
    };

    return (
      <Fragment key={id + 'summary'}>
        <RowView paddingLeft={16}>
          <div className="item-title item-label text-color-gray">{name}</div>
        </RowView>

        {choices.map(choice => (
          <ListItem
            className="disabled-ckeckbox"
            radio={isRadio}
            checkbox={isCheckBox}
            key={choice + 'summary'}
            checked={checkValue(choice, value, type)}
            value={choice}
            title={!isProduct ? choice : referenced_products.filter(filterChoiceTitle.bind(this, choice))[0].name}
          />
        ))}
      </Fragment>
    );
  }

  if (type === 'address') {
    return renderAddress(id, name, value);
  }

  if (type === 'signature') {
    return (
      <Fragment key={id + 'summary'}>
        <RowView paddingLeft={16}>
          <div className="item-title item-label text-color-gray">{name}</div>
        </RowView>

        <img
          slot="media"
          src={
            value ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7' +
              'cBAAAADUlEQVR42mP8X8/AAAAFgwGANbMDjQAAAABJRU5ErkJggg=='
          }
          width={isSmallDevice ? window.innerWidth - 32 : '160'}
          style={{ ...signatureStyle.blankImage, ...signatureStyle.centerImage }}
        />
      </Fragment>
    );
  }

  if (type === 'area') {
    return <ListInput disabled key={`${id}summary`} label={name} type="textarea" value={value} />;
  }

  return <p key={Math.random() * 100 + 'comp_not_summary'}>Component with type: {type} cannot be rendered</p>;
};

const renderBankAccount = (id, component, value, name) => {
  const { country } = component;

  if (['FR', 'BE', 'DE', 'IE'].includes(country)) {
    return (
      <React.Fragment key={id + 'summary'}>
        <ListInput label={name} type="text" value={value.registrationNumber} disabled></ListInput>
        {value.bankName && (
          <RowView>
            <ListInput
              label={I18n.t('flow:components.bankAccount.bankName')}
              type={'text'}
              className={'flex1'}
              value={value.bankName}
              disabled
            />
          </RowView>
        )}
        {value.bic && (
          <RowView>
            <ListInput
              label={I18n.t('flow:components.bankAccount.bic')}
              type={'text'}
              className={'flex1'}
              value={value.bic}
              disabled
            />
          </RowView>
        )}
      </React.Fragment>
    );
  }

  if (country === 'UK') {
    const accountNumberProp = getFieldProp(component.fields, 'accountNumber');
    const bankNameProp = getFieldProp(component.fields, 'bankName');
    const holderNameProp = getFieldProp(component.fields, 'holderName');
    const sortCodeProp = getFieldProp(component.fields, 'sortCode');

    const { bankName, holderName, accountNumber, sortCode } = value;

    return (
      <ColumnView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.bankAccount.bankName')}
            type={'text'}
            placeholder={bankNameProp?.placeholder}
            className={'input-style'}
            value={bankName}
            disabled
          />
        </RowView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.bankAccount.holderName')}
            type={'text'}
            placeholder={holderNameProp?.placeholder}
            className={'input-style'}
            value={holderName}
            disabled
          />
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}>
          <ListInput
            label={I18n.t('flow:components.bankAccount.accountNumber')}
            type={'text'}
            placeholder={accountNumberProp?.placeholder}
            className={'input-style'}
            value={accountNumber}
            disabled
          />

          <ListInput
            label={I18n.t('flow:components.bankAccount.sortCode')}
            type={'text'}
            placeholder={sortCodeProp?.placeholder}
            className={'input-style'}
            value={sortCode}
            disabled
          />
        </RowView>
      </ColumnView>
    );
  }
};

const showTitleFilter = (currentFlowData, component) => currentFlowData[component.component_key] !== undefined;

const Summary = props => {
  const {
    currentFlowData,
    currentPageIndex,
    flow_signature,
    initSubmitFlow,
    isOffline,
    mode,
    pages,
    processing,
    referenced_products,
    signatureValues,
    updateAgreement,
    updateSignature,
    userAvatar,
    userFullName,
    validBadgeNumber
  } = props;
  const showTitle = {};
  const { showSignature } = flow_signature;

  for (const page of pages) {
    showTitle[page.id] = page.components.filter(showTitleFilter.bind(this, currentFlowData)).length > 0;
  }

  return (
    <ColumnView justifyContent={'flex-start'} alignItems={'stretch'}>
      {pages.map(renderPageBlocks.bind(this, showTitle, currentFlowData, referenced_products))}

      <BlockTitle medium>{I18n.t('flow:summary.agreement')}</BlockTitle>

      <Card.Notice fill>
        <RowView justifyContent={'flex-start'} paddingHorizontal={10} paddingVertical={10}>
          {I18n.t('flow:summary.agreementWarning')}
        </RowView>
      </Card.Notice>

      <AgentInfo
        flow_signature={flow_signature}
        userAvatar={userAvatar}
        userFullName={userFullName}
        validBadgeNumber={validBadgeNumber}
      />

      {showSignature && (
        <SummarySignature
          currentPageIndex={currentPageIndex}
          mode={mode}
          updateSignature={updateSignature}
          updateAgreement={updateAgreement}
          userFullName={userFullName}
          value={signatureValues}
        />
      )}

      <RowView marginBottom={15} width={'250px'}>
        <Button.Primary
          width={'100%'}
          onClick={initSubmitFlow}
          disabled={
            isOffline ||
            processing ||
            (showSignature &&
              ((mode === 'agent' && !signatureValues?.agreement) ||
                (mode === 'donor' && signatureValues?.base64.length === 0)))
          }
          processing={processing}>
          {I18n.t('flow:submit')}
        </Button.Primary>
      </RowView>
    </ColumnView>
  );
};

Summary.propTypes = {
  currentFlowData: PropTypes.object,
  currentPageIndex: PropTypes.number,
  flow_signature: PropTypes.object,
  initSubmitFlow: PropTypes.func,
  isOffline: PropTypes.bool,
  mode: PropTypes.string,
  pages: PropTypes.array,
  processing: PropTypes.bool,
  referenced_products: PropTypes.array,
  signatureValues: PropTypes.object,
  updateAgreement: PropTypes.func,
  updateSignature: PropTypes.func,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validBadgeNumber: PropTypes.any
};

export default Summary;
